<template>
  <div class="create-ad">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col>
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>
            <el-breadcrumb-item>会员等级</el-breadcrumb-item>
            <el-breadcrumb-item>编辑/添加</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
      </el-row>
    </div>
    <!-- 主要内容 -->
    <div class="main">
      <el-form class="form-container" ref="form" :model="form" label-width="140px">
        <el-form-item label="序号">
          <el-input v-model.number="form.sorder" type="number"></el-input>
        </el-form-item>
        <el-form-item label="首页图" :error="$v.form.homeimg.$error ? '请上传首页图' : ''">
          <image-upload @upload="imageUpload" :image="form.homeimg" />
          <span>480px*270px </span>
        </el-form-item>
        <el-form-item label="等级编号" :error="$v.form.gradeno.$error ? '请输入等级编号' : ''">
          <el-input v-model="form.gradeno"></el-input>
        </el-form-item>
        <el-form-item label="等级名称" :error="$v.form.name.$error ? '请输入等级名称' : ''">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="满足积分">
          <el-input v-model="form.point"></el-input>满足积分可以升级
        </el-form-item>
        <!-- <el-form-item label="X天清零" :error="$v.form.consumptionnoday.$error ? '请输入X天清零' : ''">
          <el-input-number v-model="form.consumptionnoday"></el-input-number> <span>天</span>
        </el-form-item> -->
        <el-form-item label="每消费1元获得" :error="$v.form.moneytopoint.$error ? '请输入' : ''">
          <el-input-number v-model="form.moneytopoint"></el-input-number>
          <span>积分</span>
        </el-form-item>
        <el-form-item label="会员购买折扣" :error="$v.form.userdiscount.$error ? '请输入会员购买折扣' : ''">
          <el-input-number v-model="form.userdiscount" :precision="2" :step="0.01"></el-input-number> <span>折，如
            78折输入0.78</span>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="createAd">保存</el-button>
          <el-button @click="back">返回</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { editRequest, getdatilRequest } from "@/api/wx_MemberUserGrade";
import ImageUpload from "@/components/imageUpload";
import { required } from "vuelidate/lib/validators";
import utils from "@/utils/util";
export default {
  typeName: "Articletypecreate",
  data() {
    return {
      classify: [],
      form: {
        id: 0,
        name: "",

        addtime: ""
      }
    };
  },
  components: {
    "image-upload": ImageUpload
  },
  created() {
    this.form.id = this.$route.query["id"];

    this.getdatil();
  },
  methods: {
    back() {
      this.$router.back();
    },
    getdatil() {
      getdatilRequest({
        Id: this.form.id
      }).then(res => {
        this.form = res.data;
      });
    },
    imageUpload(url) {
      this.form.homeimg = url
    },
    createAd() {
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        let params = JSON.parse(JSON.stringify(this.form));
        params.homeimg = utils.removeUrl(params.homeimg);
        editRequest(params).then(res => {
          if (res.code == 1) {
            this.$router.go(-1);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    }
  },
  validations: {
    form: {
      name: {
        required
      },
      homeimg: {
        required
      },

      moneytopoint: {
        required
      },
      gradeno: {
        required
      },
      userdiscount: {
        required
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-cascader .el-input input {
  width: 460px;
}
</style>
